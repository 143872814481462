import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { IPlanInterval } from '../../../interfaces/components';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Error from '../../../components/global/ui/alerts/Error';
import { useAuthHeader, useAuthUser, useSignIn } from 'react-auth-kit';
import {
  AMPLITUDE_EVENTS,
  basicPlanItems,
  businessPlanItems,
  ORGANIZATION_ID,
  premiumPlanItems,
  SUBSCRIPTION_ID_KEY, pro, agency
} from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import PackageItem from '../../Plans/PlanPackageItem';
import { packages, plan } from '../../../pages/main/Plans';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';
import { useCoupon } from '../../../pages/main/Coupons/useCoupon';
import debounce from 'lodash/debounce';
interface Package {
  key: number;
  value: number;
  name: string;
  pricing: {
    month: number;
    year: number;
    total: number;
    annual:number;
    limit: number;
    range?: {
      start: number;
      end: number;
    };
    originalMonthly?: number
  };
}


export default function NewFirstTimeCheckoutForm({
  plan,
  interval
}: {
  plan: Package;
  interval: IPlanInterval;
}) {
  const { addPaymentCard, makePayment, errorMessage } = usePayment();
  const [message, setMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const signIn = useSignIn();
  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const { fetchOrganizationData, profile } = useContext(OrganizationContext);
  const [tokenType, token] = authHeader().split(' ');
  const [error, setError] = useState<string | null | undefined>(null);
  const { verifyCoupon } = useCoupon();
  const [couponCode, setCouponCode] = useState('');
  const [couponVerificationResult, setCouponVerificationResult] = useState<any>(null);
  const planKey = localStorage.getItem('plan');
  const period = localStorage.getItem('period');
  const selectedPackage = packages.find(pkg => pkg.key === Number(planKey));

  let amount = '0';
if (selectedPackage) {
  if (period === '12month' || period === 'annual'){
    amount = selectedPackage.pricing.annual.toString();
  } else {
    amount = selectedPackage.pricing.month.toString();
  }
}

const debouncedVerifyCoupon = useCallback(
  debounce(async (coupon: string) => {
    if (coupon) {
      try {
        const result = await verifyCoupon(coupon, amount);
        setCouponVerificationResult(result);
        
        if (result.result === 'success' && result.msg) {
          localStorage.setItem('newAmount', result.msg.newAmount.toString());
          localStorage.setItem('percentOff', result.msg.percentOff.toString());
        }
      } catch (error) {
        console.error('Error verifying coupon:', error);
        setCouponVerificationResult({ result: 'error', message: 'Failed to verify coupon' });
      }
    } else {
      setCouponVerificationResult(null);
      localStorage.removeItem('newAmount');
      localStorage.removeItem('percentOff');
    }
  }, 2000),
  [amount, verifyCoupon]
);

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    formik.setFieldValue('coupon', value);
    debouncedVerifyCoupon(value);
  };

  useEffect(() => {
    debouncedVerifyCoupon(couponCode);
    return () => debouncedVerifyCoupon.cancel();
  }, [couponCode]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email').required('Enter your email'),
    coupon: Yup.string(),
    number: Yup.string()
      .max(16)
      .min(14, 'Card number must be at least 14 digits')
      .trim()
      .required('Enter your card number'),
    expMonth: Yup.string()
      .typeError('Expiry month must be digits 01 - 12')
      .max(2, 'Expiry month must be at most 2 digits')
      .min(1, 'Expiry month must be at least 1 digits')
      .required('Expiry month is required'),
    expYear: Yup.string()
      .typeError('Expiry year must be digits')
      .max(2, 'Expiry year must be at most 2 digits')
      .min(2, 'Expiry year must be at least 2 digits')
      .required('Expiry year is required'),
    name: Yup.string().required('Name on card is required'),
    cvc: Yup.string().max(4).required('CVC is required')
  });

  const formik = useFormik({
    initialValues: {
      plan: plan && plan.value,
      name: '',
      coupon: '',
      interval: localStorage.getItem('period') || 'month',
      email: localStorage.getItem('email') || '',
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      country: ''
    },
    validationSchema,
    onSubmit: async (values) => handleNewSubscription(values)

  });

  const handleNewSubscription = async (values: FormikValues) => {
    setIsSubmitting(true);
    setMessage(null);
    setError(null);
    
    try {
      const addCardResponse = await addPaymentCard({
        name: values.name,
        number: values.number,
        expMonth: values.expMonth,
        expYear: values.expYear,
        cvc: values.cvc,
        addressOne: values.addressOne,
        addressTwo: values.addressTwo,
        country: values.country,
        city: values.city,
        isNewCustomer: true
      });
  
      if (addCardResponse && addCardResponse.card_token) {
        trackAmplitudeEvent(AMPLITUDE_EVENTS.CARD_ADDED_TO_ACCOUNT);
        const createCustomerResponse = await makePayment({
          name: values.name,
          email: values.email,
          plan: values.plan,
          interval: values.interval,
          coupon: values.coupon,
          cardToken: addCardResponse.card_token
        });
  
        if (createCustomerResponse && createCustomerResponse.result === 'success') {
          const { subscriptionId } = createCustomerResponse.additional;
          if (addCardResponse?.result === 'success') {
            localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
            localStorage.removeItem('plan');
            localStorage.removeItem('period');
            localStorage.removeItem('percentOff');
            localStorage.removeItem('newAmount');
            localStorage.removeItem('trial');
  
            localStorage.setItem(
              ORGANIZATION_ID,
              profile && (profile.organizations[0].account_id as string)
            );
  
            signIn({
              token,
              tokenType: 'Bearer',
              expiresIn: 720,
              authState: {
                ...auth(),
                newUser: false
              }
            });
            setTimeout(() => {
              fetchOrganizationData();
              navigate(routes.SETUP);
            }, 1000);
          } 
        } 
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={'md:w-3/5 lg:w-full'}>
      {message ? (
        <div className={'h-[50vh] flex flex-col justify-center items-center text-center'}>
          <h4 className={'text-[#008160] text-lg'}>{message}</h4>
          <p className={'text-sm text-gray-400'}>Redirecting to dashboard...</p>
        </div>
      ) : (
        <main className='container mx-auto py-[8vh]'>
          <div className="flex flex-col-reverse xl:flex-row justify-center">
            <div className=" rounded-lg border xl:w-[30%]">
              <SelectedPackageCheckout selectedPlan={plan} intervalSelected={interval} />
            </div>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
              id={'first_time_payment_card-form'}
              className="xl:w-[50%] bg-[#F1F2F3] py-10 px-10 xl:ml-4 mb-4 xl:mb-0 rounded-lg border-gray-300">
              <h4 className='text-center text-teal-700 text-xl not-italic font-semibold mb-5'>Get your free trial started!</h4>
              <div className="mb-1 xl:flex items-center justify-between">
                <label className=" text-sm font-medium text-gray-700">Name on Card</label>
                <input
                  type={'text'}
                  name={'name'}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  autoFocus={true}
                  id="name" className="mt-1 w-[100%] xl:w-[78%] p-3 border border-gray-300 rounded-md focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50" placeholder='Full name on card' />
              </div>
              <p className={'text-sm text-red-500 mb-4'}>
                {formik.touched.name && formik.errors.name}
              </p>

              <div className="mb-1 xl:flex items-center justify-between">
                <label className="text-sm font-medium text-gray-700">Card Number</label>
                <div className="relative w-[100%] xl:w-[78%]">
                  <input
                    type={'text'}
                    name={'number'}
                    maxLength={16}
                    minLength={15}
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    id="name" className="mt-1 w-full p-3 border border-gray-300 rounded-md focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 pl-4" placeholder='1234 1234 1234 1234' />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <span
                      className={`w-5 md:w-6 h-5 md:h-6 pt-1 flex justify-center items-center rounded-full border-2  ${formik.values.number.length >= 14 &&
                        formik.values.number.length <= 16
                        ? 'border-emerald-600'
                        : 'border-gray-500'
                        }`}>
                      <i
                        className={`fi fi-br-check text-[0.5rem] md:text-sm ${formik.values.number.length >= 14 &&
                          formik.values.number.length <= 16
                          ? 'text-emerald-600'
                          : 'text-gray-500'
                          }`}></i>
                    </span>
                  </div>
                </div>
              </div>
              <p className={'text-sm text-red-500 mb-4'}>
                {formik.touched.number && formik.errors.number}
              </p>

              <div className='xl:flex items-center justify-between'>
                <div className="xl:flex items-center justify-between w-[100%] xl:w-[65%]">
                  <label className=" text-sm font-medium text-gray-700">Expiration Date</label>
                  {/* <input type="text" id="name" name="name" className="mt-1 w-[63%] p-3 border border-gray-300 rounded-md focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50" placeholder='MM/YY' /> */}
                  <div className={'flex gap-3 items-center w-[100%] xl:w-[66%]'}>
                    <input
                      type={'text'}
                      name={'expMonth'}
                      value={formik.values.expMonth}
                      onChange={formik.handleChange}
                      placeholder={'MM'}
                      minLength={2}
                      maxLength={2}
                      className={
                        'outline-0 border border-t-gray-300 border-b-gray-300 border-l-gray-300 w-[100%] rounded-md p-2.5 text-center'
                      }
                    />
                    {'/'}
                    <input
                      type={'text'}
                      name={'expYear'}
                      minLength={2}
                      maxLength={2}
                      placeholder={'YY'}
                      value={formik.values.expYear}
                      onChange={formik.handleChange}
                      className={
                        'outline-0 border border-t-gray-300 border-b-gray-300 border-r-gray-300  w-[100%] rounded-md p-2.5 text-center'
                      }
                    />
                  </div>
                </div>
                <div className=" xl:flex items-center justify-between w-[100%] xl:w-[30%] mt-4 xl:mt-0">
                  <label className=" text-sm font-medium text-gray-700">CVV</label>
                  <input
                    type={'text'}
                    name={'cvc'}
                    minLength={3}
                    maxLength={
                      formik.values.number.length === 14 ||
                        formik.values.number.length === 16
                        ? 3
                        : 4
                    }
                    value={formik.values.cvc}
                    onChange={formik.handleChange}
                    id="cvc" className="w-[100%] xl:w-[80%] p-2.5 border border-gray-300 rounded-md focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50 text-center" placeholder='CVV' />
                </div>
              </div>

              <p className={'text-sm text-red-500 mb-4'}>
                {
                  (formik.touched.expMonth && formik.errors.expMonth) ||
                  (formik.touched.expYear && formik.errors.expYear) ||
                  (formik.touched.cvc && formik.errors.cvc)}
              </p>

              <div className="mb-4 xl:flex items-center justify-between">
                <label className=" text-sm font-medium text-gray-700">Email</label>
                <input type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  id="email" name="email" className="mt-1 w-[100%] xl:w-[78%]  p-3 border border-gray-300 rounded-md focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50" placeholder='Test@testmail.com' />
              </div>

              <div className="mb-4 xl:flex items-center justify-between">
          <label className="text-sm font-medium text-gray-700">Discount Code</label>
          <input
            onChange={handleCouponChange}
            type="text"
            value={formik.values.coupon}
            id="coupon"
            name="coupon"
            className="mt-1 w-[100%] xl:w-[78%] p-3 border border-gray-300 rounded-md focus:border-emerald-500 focus:ring focus:ring-emerald-500 focus:ring-opacity-50"
            placeholder=''
          />
        </div>
              <div className='flex justify-center items-center mt-8'>
              <button
                disabled={!formik.values.name || !formik.values.email || !formik.values.number || !formik.values.expMonth || !formik.values.expYear || isSubmitting}
                className={`rounded-lg text-white font-bold py-[16px] px-[24px] ${
                  !formik.values.name || !formik.values.email || !formik.values.number || !formik.values.expMonth || !formik.values.expYear || !formik.values.cvc || isSubmitting
                    ? 'bg-emerald-200 hover:bg-emerald-200  text-white'
                    : 'bg-[#08916F] hover:bg-[#067a5f]'
                }`}
                type='submit'
              >
                {isSubmitting ? 'Processing Subscription...' : 'Get Started!'}
              </button>
            </div>
              <div className='w-[100%] mx-auto'>
                {errorMessage && <Error value={errorMessage} />}
              </div>

            </form>
          </div>

          <div className='flex flex-col lg:flex-row justify-center mt-7'>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 w-[100%] lg:w-[81%] justify-center">
              <div className="bg-[#2E9A6E] p-5 rounded-md shadow-md xl:flex justify-center items-center text-center">
                <div className=' flex flex-col justify-center'>
                  <div className='flex justify-center'>
                    <img src="https://i.postimg.cc/yx8sfk84/12.png" alt="" className='w-[60px] h-[60px]' />
                  </div>
                  <span className='text-center text-[12px] not-italic font-semibold leading-5 text-[#fff]'>@mordechaihoff</span>
                  <p className='text-center text-[12px] not-italic font-medium leading-5 text-[#fff] mb-3 xl:mb-0'>CEO | Red Mose</p>
                </div>
                <div className='xl:ml-10'>
                  <small className='text-[12px] text-white font-medium'>We&lsquo;ve been looking closely at how users move through our site to find out what&lsquo;s driving the most revenue. I&lsquo;ve never seen such useful information before. It&lsquo;s like I&lsquo;m more in control than I&lsquo;ve ever been. By the way, I&lsquo;ve tried all sorts of heatmap tools, but this one beats them by a mile.</small>
                </div>
              </div>
              <div className="bg-[#2E9A6E] p-5 rounded-md shadow-md xl:flex justify-center items-center text-center">
                <div className=' flex flex-col justify-center'>
                  <div className='flex justify-center'>
                    <img src="https://i.postimg.cc/Gtgr6d4B/32.png" alt="" className='w-[60px] h-[60px]' />
                  </div>
                  <span className='text-center text-[12px] not-italic font-semibold leading-5 text-[#fff]'>@AllanJPorter</span>
                  <p className='text-center text-[12px] not-italic font-medium leading-5 text-[#fff] mb-3 xl:mb-0'>Owner | Porter Media</p>
                </div>
                <div className='xl:ml-10'>
                  <small className='text-[12px] text-white font-medium'>
                    This is the only heatmap where I&lsquo;m able to make decisions on my website in seconds because no other software shows revenue on-site. Changed our entire business for such a low price.
                  </small>
                </div>
              </div>
            </div>
          </div>

        </main>
      )}
    </div>
  );
}
function SelectedPackageCheckout({
  intervalSelected,
  selectedPlan
}: {
  intervalSelected: any;
  selectedPlan: Package;
}) {
  const period = localStorage.getItem('period');
  const isYearly = period === '12month' || period === 'year' || period === 'annual';
  return (
    <div
      className={`flex flex-col justify-between rounded-md bg-white  border border-[#30E8AB]`}>
      <div>
        {selectedPlan.value == 2 && (
          <span
            className={
              'w-full rounded-t-md absolute top-0 left-0 h-[0.625rem] bg-[#091226]'
            }></span>
        )}

        <div className={'mt-5 mb-10 flex items-end gap-4 leading-none bg-white'}>

          <div className="rounded-lg px-5">
          <div className='pt-4'>
            <h5 className='font-bold text-[25px] mb-5'>{selectedPlan.name}</h5>
            <div className='flex items-center gap-2 mb-2'>
              {isYearly && (
                <span className='text-[12px] line-through text-gray-500 self-center'>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(selectedPlan.pricing.total * 12)}
              </span>
              )}
              <h6 className='m-0 p-0 flex items-center'>
              <span className='text-[42px] text-[#00C291] font-bold'>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(
                  localStorage.getItem('newAmount')
                    ? parseFloat(localStorage.getItem('newAmount') || '0')
                    : isYearly
                    ? selectedPlan.pricing.annual
                    : selectedPlan.pricing.month
                )}
              </span>
              <small className='text-gray-700 ml-1 self-end'>
                {isYearly ? '/year' : '/month'}
              </small>
            </h6>
            </div>
            <p className='m-0 p-0 text-sm'>
            7 day trial then billed monthly
            </p>
          </div>

          <p className='pt-3'>

          {localStorage.getItem('percentOff') && parseFloat(localStorage.getItem('percentOff') || '0') > 0 && (
           <span className='pt-4 text-[12px]'>
           <span className='bg-[#DAFBED] py-2 px-2 rounded-md text-[#008060] font-bold'>{localStorage.getItem('percentOff')}% OFF</span> discount applied
           </span>
          )}
          </p>

            <div>
              <div className='mt-7'>
                <h6 className='text-[14px] mb-4 font-semibold'>What’s included:</h6>
                          {pro.map((item, index) => (
                            <li key={index} className="flex items-center mb-4">
                              <span className="top-0 left-0 pr-3">
                                <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                                </svg>
                              </span>
                              <small>{item}</small>
                            </li>
               ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
