import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useContext, useState, useEffect, useRef } from 'react';
import { plans, packages } from '../../pages/main/Pricing';
import { useSubscription } from '../../pages/main/AccountSubscription/useSubscription';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import { IWebsiteSubscription } from '../../interfaces/subscription';
import {
  basicPlanItems,
  businessPlanItems,
  premiumPlanItems,
  freemium, agency, pro,
  ORGANIZATION_ID,
  AGENCY_BOOKING_URL
} from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { IPlan, IPackage } from '../../interfaces/components';
import { useNavigate } from 'react-router-dom';
import AddPaymentCard from '../../feature/AccountSubscription/Payments/AddPaymentCard';


export default function UpgradeSubscription({
  currentPlan,
  upgradePlan,
  currentInterval,
  subscriptionId,
  closeModal,
  accountName,
  url
}: {
  subscriptionId: string;
  currentInterval: string;
  currentPlan: number;
  upgradePlan?: number | string;
  closeModal: () => void;
  accountName?: string;
  url?: string;
}) {
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState<IWebsiteSubscription[] | []>([]);
  const [loading2, setLoading] = useState<boolean>(true);
  const currentUrl = useRef(window.location.href);
  const getItemFromUrl = (item: string) => {
    const parsedUrl = new URL(currentUrl.current);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const hashParams = new URLSearchParams(parsedUrl.hash.slice(1));
    return searchParams.get(item) || hashParams.get(item) || "";
  };

  const validationSchema = Yup.object().shape({
    upgradePlan: Yup.string().required('Select your new plan')
  });

  const { upgradeSubscription, loading, errorMessage } = useSubscription();
  const { profile } = useContext(OrganizationContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [currentPlan2, setCurrentPlan] = useState<IPackage>(packages.find(pkg => pkg.key === currentPlan) || packages[7]);
  const currentUserWebsite = searchResults?.find(data => data.idsite === +getItemFromUrl('siteId') && data.plan === +getItemFromUrl('planId') && 'can_upgrade' in data && data.can_upgrade === true && getItemFromUrl('is_upgrade') === '1');


  useEffect(() => {
    if (currentUserWebsite) {
      openModal();
    }
  }, [currentUserWebsite]);

  useEffect(() => {
    if (currentUserWebsite) {
      // Set the minimum range to the current plan's range start
      setRevenue(currentPlan2.pricing.range?.start || 0);
      // Find the next available plan
      const nextPlan = packages.find(pkg => pkg.key > currentPlan2.key);
      const upgradePackage = packages.find(pkg => pkg.key === upgradePlan);

      if (upgradePackage) {
        // Infer the default interval based on the pricing
        const hasYearlyPricing = upgradePackage.pricing.year !== undefined && upgradePackage.pricing.year > 0;
        const hasMonthlyPricing = upgradePackage.pricing.month !== undefined && upgradePackage.pricing.month > 0;
        
        // Set isYearly to true if there's only yearly pricing or if both are available (assuming yearly as default in this case)
        setIsYearly(hasYearlyPricing && (!hasMonthlyPricing || upgradePackage.pricing.year < upgradePackage.pricing.month * 12));
      }
      if (nextPlan) {
        setSelectedPlan(nextPlan);
        formik.setFieldValue('upgradePlan', nextPlan.key);
      } else {
        // If no next plan, keep the current plan selected
        setSelectedPlan(currentPlan2);
        formik.setFieldValue('upgradePlan', currentPlan2.key);
      }
    } else {
      // If not currentUserWebsite, keep the existing logic
      const nextAvailablePlan = packages.find(pkg => pkg.key > currentPlan2.key) || currentPlan2;
      setRevenue(nextAvailablePlan.pricing.range?.start || currentPlan2.pricing.range?.end || 0);
    }
  }, [currentUserWebsite, currentPlan2, packages]);

  const [isYearly, setIsYearly] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(currentInterval);

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const formik = useFormik({
    initialValues: {
      upgradePlan: upgradePlan ? upgradePlan : undefined,
      interval: 'month',
    },
    validationSchema,
    onSubmit: async (values) => handleUpgradeSubscription(values)
  });

  const handleUpgradeSubscription = async (values: FormikValues) => {
    setMessage(null);
    const response = await upgradeSubscription({
      currentPlan,
      upgradePlan: values.upgradePlan,
      interval: isYearly ? 'year' : 'month',
      subscriptionId,
    });
    if (response?.result === 'success') {
      setMessage(response.msg);
      localStorage.removeItem('trial');
      setSelectedInterval(isYearly ? 'year' : 'month');
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      setTimeout(() => {
        hideModal();
        closeModalHandler();
      }, 2000);
    }
  };


  const planItemsMap: { [key: number]: string[] } = {
    1: pro,
    2: businessPlanItems,
    3: premiumPlanItems,
    4: premiumPlanItems,
    5: agency,
    6: pro,
    7: freemium,
  };

  const nextAvailablePlan = packages.find(pkg => pkg.key > currentPlan2.key) || currentPlan2;
    const [revenue, setRevenue] = useState<number>(() => {
      const nextAvailablePlan = packages.find(pkg => pkg.key > currentPlan2.key) || currentPlan2;
      return Math.max(nextAvailablePlan.pricing.range?.start || currentPlan2.pricing.range?.end || 0, 100000);
    });

  const [selectedPlan, setSelectedPlan] = useState<IPackage>(nextAvailablePlan);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModalHandler = () => {
    setIsModalVisible(false);
    closeModal();
    const pathParts = window.location.pathname.split('/');
    const basePath = pathParts.slice(0, 3).join('/');
    navigate(basePath, { replace: true });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value === '') {
      setRevenue(100000);
    } else {
      const newRevenue = Math.max(parseInt(value), 100000);
      setRevenue(newRevenue);
      updatePackageBasedOnRevenue(newRevenue);
    }
  };

  const updatePackageBasedOnRevenue = (revenue: number) => {
    const adjustedRevenue = Math.max(revenue, 100000);
  
    const newSelectedPackage = packages.find(pkg =>
      pkg.pricing &&
      pkg.pricing.range &&
      adjustedRevenue >= pkg.pricing.range.start &&
      adjustedRevenue <= pkg.pricing.range.end &&
      pkg.key >= 1
    ) || packages[packages.length - 1];
  
    setSelectedPlan(newSelectedPackage);
    formik.setFieldValue('upgradePlan', newSelectedPackage.key);
  };

  const [isOpen, setIsOpen] = useState(false);

  const displayModal = () => setIsOpen(true);

  const hideModal = () => setIsOpen(false);

  useEffect(() => {
    setLoading(true);
    if (profile && profile.subscription_details?.subscription) {
      setSearchResults(profile.subscription_details?.subscription as IWebsiteSubscription[]);
      setLoading(false);
    }
  }, [profile]);

  useEffect(() => {
    setSelectedInterval(currentInterval);
  }, [currentInterval]);


  return (
    <>

      <div
        className="fixed z-10 inset-0 overflow-y-auto w-full h-[100%]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"></div>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <div
            className={`relative inline-block align-bottom bg-white  rounded text-left overflow-auto shadow-xl transform transition-all sm:my-8 md:my-0 sm:align-middle w-full h-[100vh]`}>
            <div className={'bg-white p-3 pb-5 w-full h-[100vh]'}>
              <div className={'flex justify-end py-2 px-10'}>
                <button type={'button'} onClick={closeModalHandler}>
                  <i className="fi fi-ss-cross text-xs"></i>
                </button>
              </div>
              <hr className={'border-b border-gray-100 my-2'} />
              <div className="mb-[8vh] pb-[8vh] mt-[3vh]">
                <h2 className="text-gray-900 text-center text-xl font-bold leading-tight mb-2">Upgrade Your Plan</h2>
                <h6 className="text-gray-900 text-center text-[13px] lg:text-[14px] font-normal leading-6">Pricing is based on your annual revenue</h6>

                <div className="flex flex-col lg:flex-row items-center justify-center mt-16">
                  <p className="text-gray-900 text-center font-medium text-[12px] lg:text-[16px] leading-6 text-md">What&lsquo;s your annual revenue?: </p>

                  <div className="flex w-[200px] lg:ml-2 ">
                    <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 rounded-l-lg border-gray-300 border-e-0 rounded-s-md"> $ </span>
                    <input type="text" id="website-admin" className="rounded-none rounded-e-lg rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 text-end " value={typeof revenue === 'number' ? revenue.toLocaleString() : ''}
                      onChange={handleInputChange} />
                  </div>
                </div>

                <div className="flex items-center justify-center mt-2 mb-16">
                  <span className="mr-3 text-gray-900"> 100K </span>
                  <input
                    type="range"
                    min={100000}
                    max={100000000}
                    step="1"
                    value={revenue}
                    onChange={(e) => {
                      const newRevenue = parseInt(e.target.value);
                      setRevenue(newRevenue);
                      updatePackageBasedOnRevenue(newRevenue);
                    }}
                    className="w-[600px] bg-gray-200 h-4 rounded-full outline-none appearance-none transition-colors duration-300 ease-in-out"
                    style={{
                      background: (() => {
                        const start = 100000;
                        const range = 100000000 - start;
                        const percentage = ((revenue - start) / range) * 100;
                        return `linear-gradient(to right, #08916F 0%, #08916F ${percentage}%, #D1D5DB ${percentage}%, #D1D5DB 100%)`;
                      })()
                    }}
                  />
                  <span className="ml-4 text-gray-900">$100m+</span>
                  <style>{`
          /* Style the range input */
          input[type=range] {
            -webkit-appearance: none;
            appearance: none;
            height: 4px;
            background: transparent;
            cursor: pointer;
          }

          /* Firefox */
          input[type=range]::-moz-range-thumb {
            background-color: #08916F;
            border: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            cursor: pointer;
          }

          /* WebKit/Blink */
          input[type=range]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: #08916F;
            cursor: pointer;
          }

          /* Edge */
          input[type=range]::-ms-thumb {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: #08916F;
            cursor: pointer;
          }
        `}
                  </style>

                </div>
                <div className='text-center'>
                  <div className='flex justify-center'>
                    <label className="inline-flex items-center cursor-pointer">
                      <input 
                        type="checkbox" 
                        className="sr-only"
                        checked={isYearly}
                        onChange={() => setIsYearly(!isYearly)}
                      />
                                <span className={`ms-3 text-sm font-medium ${isYearly ? 'text-gray-500' : 'text-gray-900'} mr-3`}>Month-to-Month</span>
                    <div className="relative w-11 h-[1.6rem] bg-white border border-[#08916F] rounded-full peer">
                      <div 
                        className={`absolute top-[2px] ${isYearly ? 'right-[2px]' : 'left-[2px]'} bg-[#08916F] rounded-full h-5 w-5 transition-all duration-300 ease-in-out`}
                      ></div>
                    </div>
                    <span className={`ms-3 text-sm font-medium ${isYearly ? 'text-gray-900' : 'text-gray-500'} ml-3`}>12-Month Agreement</span>
                    </label>
                  </div>
                  <small className='text-[11px] text-[#08916F] font-medium'>Save 20% with 12-months agreement</small>
                </div>

                <div className='mt-10'>
                <div className="flex flex-col lg:flex-row justify-between items-end w-full lg:w-[80%] mx-auto">
  {/* Current Plan */}
  <div className="w-full lg:w-1/3 p-4 border mb-4 lg:mb-0 lg:mr-3 rounded-lg">
    <div className='flex justify-between'>
      <h2 className="text-xl font-bold pt-2">{currentPlan2.name}</h2>
      <div className='flex flex-col'>
        <span className='text-[30px] text-[#00c291] font-bold'>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(selectedInterval === 'year' ? currentPlan2.pricing.annual ?? 0 : currentPlan2.pricing.month ?? 0)}
        </span>
        <p className='text-end text-[12px]'>
          {selectedInterval === 'year' ? '/per year' : '/per month'}
        </p>
      </div>
    </div>

    <button className='border bg-[#F1F2F3] text-[#73808C] py-3 w-full mt-5 rounded-lg text-sm font-medium'>
      Your current plan
    </button>
    <div className='mt-8'>
      <h6 className='text-[14px] mb-4 font-semibold'>What&lsquo;s included:</h6>
      {(currentPlan2.key === 9
        ? planItemsMap[6]
        : currentPlan2.key >= 10 && currentPlan2.key <= 22
          ? planItemsMap[6]
          : planItemsMap[6] || []
      )?.map((item, index) => (
        <li key={index} className="flex items-center mb-3">
          <span className="top-0 left-0 pr-3">
            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
            </svg>
          </span>
          <small>{item}</small>
        </li>
      ))}
    </div>
  </div>

  {/* Selected Plan (changes based on range) */}
  <div className='w-full lg:w-1/3 mb-4 lg:mb-0'>
    {selectedPlan && (
      <>
        <div className='bg-[#DAFBED] lg:mr-3 rounded-t-lg text-center text-[11px] font-bold text-[#53C768] py-3'>
          🔥 {currentUserWebsite ? 'AVAILABLE UPGRADE OPTIONS' : 'AVAILABLE PLAN OPTIONS'}
        </div>
        <div className="p-4 border lg:mr-3 rounded-b-lg border-[#30E8AB]">
          <div className='flex justify-between'>
            <h2 className="text-xl font-bold">{selectedPlan.name}</h2>
            <div>
              <div className='flex items-center'>
                <p className='mr-2'>
                  {isYearly && (
                    <span className='text-[12px] text-gray-500 line-through ml-2'>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format((selectedPlan.pricing.total ?? 0) * 12)}
                    </span>
                  )}
                </p>
                <span className='text-[30px] text-[#00c291] font-bold text-right'>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(isYearly ? selectedPlan.pricing.annual ?? 0 : selectedPlan.pricing.month ?? 0)}
                </span>
              </div>
              <p className='text-end text-[12px]'>
                {isYearly ? '/per year' : '/per month'}
              </p>
            </div>
          </div>
          <button
            className={`border ${currentUserWebsite
              ? (selectedPlan.key > currentPlan2.key
                ? 'border-[#008160] hover:bg-[#008160] text-[#008160] hover:text-white'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed')
              : 'border-[#008160] hover:bg-[#008160] text-[#008160] hover:text-white'
            } py-3 px-4 mt-5 rounded-lg text-sm font-medium w-full`}
            onClick={displayModal}
            disabled={currentUserWebsite && selectedPlan.key <= currentPlan2.key}
          >
            {currentUserWebsite
              ? (selectedPlan.key > currentPlan2.key ? 'Change Plan' : 'No Upgrade Available')
              : 'Change Plan'
            }
          </button>
          <div className='mt-8'>
            <h6 className='text-[14px] mb-4 font-semibold'>What&lsquo;s included:</h6>
            {(selectedPlan.key === 9
              ? planItemsMap[7]
              : selectedPlan.key >= 10 && selectedPlan.key <= 22
                ? planItemsMap[6]
                : planItemsMap[selectedPlan.key] || []
            )?.map((item, index) => (
              <li key={`selected-${selectedPlan.key}-${index}`} className="flex items-center mb-3">
                <span className="top-0 left-0 pr-3">
                  <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                  </svg>
                </span>
                <small>{item}</small>
              </li>
            ))}
          </div>
        </div>
      </>
    )}
  </div>

  {/* Enterprise Plan */}
  <div className="w-full lg:w-1/3 p-4 border rounded-lg lg:h-[565px]">
    <div className='flex justify-between mb-14'>
      <h2 className="text-xl font-bold">Enterprise</h2>
      <div>
        <p className='text-[20px] text-[#00c291] font-bold text-end p-0 m-0'>Book a call</p>
      </div>
    </div>
    <a href={AGENCY_BOOKING_URL} target='blank' className='border-[#008160] border hover:bg-[#008160] text-[#008160] hover:text-white py-3 w-full block text-center cursor-pointer mt-5 rounded-lg text-sm font-medium'>Book a call</a>
    <div className='mt-8'>
      <h6 className='text-[14px] mb-4 font-semibold'>What&lsquo;s included:</h6>
      {agency.map((item, index) => (
        <li key={index} className="flex items-center mb-4">
          <span className="top-0 left-0 pr-3">
            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
            </svg>
          </span>
          <small>{item}</small>
        </li>
      ))}
    </div>
  </div>
</div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center ">

      {isOpen && (
  <div className="fixed inset-0 z-50 overflow-hidden bg-gray-800 bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded overflow-hidden shadow-lg  mx-4  overflow-y-auto w-[100%] xl:w-[600px] justify-between">
      {message ? (
        <SuccessMessage message={message} />
      ) : (profile && profile.profile_details?.super_user !== 1 && profile.subscription_details?.card.length === 0) ? (
        <div className="text-center">
          <AddPaymentCard
            closeModal={() => setIsOpen(false)}
            customerId={profile.subscription_details.stripe_customer_id}
          />
        </div>
      ) : (
        <>
          <div className='flex justify-between items-center  border-gray-200 border-b-2 mb-6'>
            <h2 className="text-lg font-bold mb-4">Confirm plan upgrade</h2>
            <i className="fi fi-ss-cross text-xs hover:cursor-pointer" onClick={hideModal}></i>
          </div>
          <p className="mb-6 text-sm">
            {`You are about to upgrade your plan to `}
            <span className='font-bold'>Pro</span>
            {` and a price of `}
            <span className='font-bold'>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(isYearly ? selectedPlan.pricing.annual ?? 0 : selectedPlan.pricing.month ?? 0)}
            </span>   {isYearly ? '/per year' : '/per month'}
            {`. Do you wish to continue?`}
          </p>

          {profile && profile.profile_details?.super_user === 1 && (
          <div className="mb-6">
          <label htmlFor="trialDays" className="block text-sm font-medium text-gray-700 mb-2">
            Select trial period:
          </label>
          <select
            id="trial"
            name="trial"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none  sm:text-sm rounded-md"
            // defaultValue="0"
            defaultValue={localStorage.getItem('trial') || "0"}
            onChange={(e) => {
              const selectedTrialDays = e.target.value;
              localStorage.setItem('trial', selectedTrialDays);
            }}>
          <option value="0">0 days</option>
          <option value="7">7 days</option>
          <option value="10">10 days</option>
          <option value="14">14 days</option>
          <option value="21">21 days</option>
          <option value="30">30 days</option>
        </select>
      </div>
          )}

          {errorMessage && (
            <div className='text-center justify-center block mx-auto mb-3'>
              <Error value={errorMessage} />
            </div>
          )}

          <div className='flex flex-col sm:flex-row justify-between gap-4 mt-3'>
            <button
              className="w-full sm:w-auto border border-gray-500 hover:border-gray-300 hover:bg-gray-300 text-black py-2.5 px-4 rounded-lg text-sm font-medium"
              onClick={hideModal}>
              Cancel
            </button>
            <button
              className='w-full sm:w-auto border-[#008160] border hover:bg-[#008160] text-[#008160] hover:text-white py-2.5 px-4 rounded-lg text-sm font-medium'
              onClick={async () => {
                formik.setFieldValue('upgradePlan', selectedPlan.key);
                try {
                  await formik.submitForm();
                } catch (error) {
                  console.error("Error submitting form:", error);
                }
              }}
              disabled={loading} >
              {loading ? 'Processing...' : (currentPlan2.key < selectedPlan.key ? 'Upgrade Plan' : 'Change Plan')}
            </button>
          </div>
        </>
      )}
    </div>
  </div>
)}
      </div>

    </>
  );
}
