export default function SubscriptionInvoicesListItemCard({
  data,
  plan,
  plan_name
}: {
  data: any;
  plan: string | number;
  plan_name: string;
}) {
  return (
    <div
      className={
        'grid grid-cols-12 gap-2 md:gap-5 p-3 rounded border border-gray-200 cursor-default'
      }>
      <div className={'col-span-12 lg:col-span-6'}>
        <span className={'text-sm md:text-base font-medium'}>{data.customer_name}</span>
        <div className={'flex gap-2 flex-col font-light'}>
          <span className={'text-xs'}>{data.customer_email}</span>
          <span className={'text-xs font-light'}>#INV{data.number}</span>
        </div>
      </div>
      <div className={'col-span-12 lg:col-span-3'}>
        <h5 className={'text-sm'}>{data.created}</h5>
      </div>
      <div className={'col-span-4 lg:col-span-1'}>
  <h5 className={'font-semibold text-base'}>
    ${(data.amount_paid / 100).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
  </h5>
  <h5 className={'text-gray-400'}>{plan_name}</h5>
</div>
      <div className={'col-span-4 lg:col-span-1'}>
        {data.status !== 'void' && (
          <span
            className={
              'font-medium uppercase text-xs rounded-full bg-green-100 text-green-600 px-2 py-1'
            }>
            {data.status}
          </span>
        )}
      </div>
      <div className={'col-span-4 lg:col-span-1'}>
        <div className={'flex justify-end'}>
          <a
            title={'Download invoice'}
            target={'_blank'}
            href={data.invoice_pdf}
            className={'text-gray-500 flex items-start justify-center gap-2 rounded-full'}
            rel="noreferrer">
            <i className="fi fi-ss-download text-xl text-emerald-400 hover:text-emerald-600"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
